<template>
  <v-card width="100%" class="ma-0 pa-0" :color="backgroundColor" flat tile dense>
    <div :id="'waveform-' + this.track.id" />
  </v-card>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import axios from 'axios'
import { saveAs } from 'file-saver'

export default {
  name: 'TrackSurfer',
  components: {},
  data () {
    return {
      wavesurfer: {}
    }
  },
  props: ['track', 'play', 'playedTime', 'height', 'backgroundColor', 'waveColor'],
  methods: {
    skipForward: function () {
      const skipTime = this.wavesurfer.getCurrentTime() + this.wavesurfer.getDuration() / 25
      this.wavesurfer.setCurrentTime(skipTime)
    },
    skipBackward: function () {
      const skipTime = Math.max(0, this.wavesurfer.getCurrentTime() - this.wavesurfer.getDuration() / 25)
      this.wavesurfer.setCurrentTime(skipTime)
    },
    stop: function () {
      this.wavesurfer.stop()
      this.$emit('played', 0, 'stop')
    },
    setCurrentTime: function (playedTime) {
      this.wavesurfer.setCurrentTime(playedTime)
    }
  },
  mounted: function () {
    const that = this
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform-' + that.track.id,
      progressColor: this.$vuetify.theme.currentTheme.progress,
      cursorColor: this.$vuetify.theme.currentTheme.action,
      barWidth: 2,
      barHeight: 1,
      backend: 'MediaElement',
      height: that.height,
      backgroundColor: that.backgroundColor,
      waveColor: that.waveColor
    })

    if (this.track.pcm) {
      axios.get(this.track.pcm).then(response => {
        this.wavesurfer.load(this.track.src, response.data)
      })
    } else {
      this.wavesurfer.load(this.track.src)
    }

    this.wavesurfer.on('audioprocess', function () {
      that.$emit('played', that.wavesurfer.getCurrentTime(), 'audioprocess')
    })

    this.wavesurfer.on('seek', function () {
      that.$emit('played', that.wavesurfer.getCurrentTime(), 'seek')
    })

    this.wavesurfer.on('waveform-ready', function () {
      if (!that.track.pcm) {
        that.wavesurfer.exportPCM(1024, 10000, false).then(pcmData => {
          const blob = new Blob([JSON.stringify(pcmData)], { type: 'text/plain;charset=utf-8' })
          saveAs(blob, `${that.track.src}.json`)
        })
      }
    })

    this.wavesurfer.on('ready', function () {
      that.$emit('duration', that.wavesurfer.getDuration())
    })
  },
  watch: {
    play: function (play) {
      if (play) {
        this.wavesurfer.play()
      } else {
        this.wavesurfer.pause()
      }
    }
  }
}
</script>
